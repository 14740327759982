import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedFloor: {},
    selectedTypology: {},
    isLoading: true
  },
  mutations: {
    setFloor(state, floor){
      state.selectedFloor = floor
    },
    setTypology(state, typology){
      state.selectedTypology = typology
    },
    setLoading(state, flag){
      state.isLoading = flag
    }
  },
  actions: {
    setFloor({commit}, floor){
      commit('setFloor', floor)
    },
    setTypology({commit}, typology){
      commit('setTypology', typology)
    },
    setLoading({commit}, flag){
      commit('setLoading', flag);
    }
  },
  getters: {
    selectedFloor(state){
      return state.selectedFloor;
    },
    selectedTypology(state){
      return state.selectedTypology;
    },
    isLoading(state){
      return state.isLoading
    }
  }
})