<template>

  <div class="side-menu prev">
    <div class="brand-logo text-center">
      <img src="@/assets/images/defaultlogo.jpg" alt="" class="img-fluid">
    </div>
    <ul class="custom-inline-box row" v-if="selectedTypology.value && selectedTypology.area && selectedTypology.all_in_price">
      <li class="list-group-item active col-12"><span style="margin-top: 5px; display: inline-block;">Plot Selected</span>
        <!-- <a @click="$bvModal.show('modal-arial-view')" href="javascript:void(0)" class="btn-aerial">Watch Aerial View</a>-->
        </li>
      <li class="list-group-item list-group-item-action col-md-6">
        <span class="title">Plot Number</span>
        <span class="info">{{selectedTypology.value}}</span>

      </li>
      <li class="list-group-item list-group-item-action col-md-6" v-if="booked_flat_details">
        <span class="title">Status</span>
        <span class="info">Booked</span>
      </li>
      <li class="list-group-item list-group-item-action col-md-6" v-if="blocked_flat_details">
        <span class="title">Status</span>
        <span class="info">Blocked</span>
      </li>
      <li class="list-group-item list-group-item-action col-md-6" v-if="!booked_flat_details & !blocked_flat_details">
        <span class="title">Area sq.ft</span>
        <span class="info">{{selectedTypology.area}}</span>
      </li>
      <li class="list-group-item list-group-item-action col-md-6" v-if="!booked_flat_details & !blocked_flat_details">
        <span class="title">Total Price</span>
        <span class="info">{{selectedTypology.all_in_price}}</span>
      </li>
      <li class="list-group-item col-md-12">
        <p class="small" v-if="!booked_flat_details">*Price includes GST, Stamp Duty, Registration charges. It does not include Maintenance.</p>
        <div class="mbtn-align">

          <button type="button" class="btn btn-primary nextButton" :disabled="!selectedTypology.key || booked_flat_details || blocked_flat_details" @click="goPlotDetails()">Next</button>
        </div>
      </li>
    </ul>
    <div class="image-gallery">
      <h3>Image Gallery</h3>
      <div>
  <b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    fade
    controls
    img-width="1024"
    img-height="480"
  >
    <b-carousel-slide> <template #img>
        <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image1.jpg" data-fancybox="gallery">
          <img class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image1.jpg" alt="">
          </a>
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image2.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image2.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image3.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image3.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image4.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image4.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image5.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image5.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image6.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image6.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image7.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image7.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image8.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image8.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <a href="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image9.jpg" data-fancybox="gallery">
        <img
            class="d-block img-fluid w-100"
            src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image9.jpg"
            alt=""
          >
          </a>
          </template>
  </b-carousel-slide>

  </b-carousel>
</div>

    </div>
  </div>
</template>
<script>
import {masterJson} from '@/assets/scripts/utility.js'
export default {
  props: {
   booked_plots: {
    type: Array,
    required: true
   },
   blocked_plots: {
    type: Array,
    required: true
   },
  },
  methods: {
    selectTypology(typology){
      this.$store.dispatch('setTypology', typology);
    },
    goPlotDetails(){
      this.$router.push({name: 'PlotDetail', params: {plot: this.selectedTypology.key}})
    },
  },
  computed: {
    booked_flat_details() {
      if (this.booked_plots.length > 0) {
        return this.booked_plots.map(b => b.key).includes(this.selectedTypology.key)
      } else {
        return false
      }
    },
    blocked_flat_details() {
      if (this.blocked_plots.length > 0) {
        return this.blocked_plots.map(b => b.key).includes(this.selectedTypology.key)
      } else {
        return false
      }
    },
    typologies(){
      return masterJson.Typologies
    },
    selectedTypology(){
      return this.$store.getters.selectedTypology;
    }
  }
}
</script>
<style scoped>
.side-menu{
  background: transparent;
  box-shadow: none;
}
.brand-logo.text-center {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px;
}
.custom-inline-box {
  background: #fff;
  padding:0;
  margin:0;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
}
.list-group-item {
  border:none;
}
.list-group-item .title{
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  opacity: 0.5;
  display: block;
}
.list-group-item .info{
  font-weight: 500;
  font-size: 15px;
  color: #222222;
}
.list-group-item:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.list-group-item .small{
  color: #222222;
  font-weight: 400;
  font-size: 10px;
  opacity: 0.5;
}
.list-group-item-action:hover, .list-group-item-action:focus{
  background-color: #fff;
}
.image-gallery {
  background: #fff;
  box-shadow: 1px 2px 50px rgba(0, 0, 0, 0.01);
  border-radius: 8px 8px 0px 0px;
}
.image-gallery h3{
  font-weight: 600;
  font-size: 15px;
  color: #222222;
  padding: 20px;
  margin-bottom:0;
}
@media  (max-width: 767px){
  .brand-logo{
    display: none;
  }
}
</style>