<template>
  <div>
    <div v-if="booked_flat_details">
      <div class="row plot-info">
        <div class="col-6 pr-0" >
          <span class="title">Status</span>
          <span class="info">Booked</span>
        </div>
      </div>
    </div>
    <div v-if="blocked_flat_details">
      <div class="row plot-info">
        <div class="col-6 pr-0" >
          <span class="title">Status</span>
          <span class="info">Blocked</span>
        </div>
      </div>
    </div>
      <div v-if="!booked_flat_details & !blocked_flat_details">
        <div class="row plot-info" v-if="!building.image">
        <div class="col-6 pr-0" v-if="building.area">
          <span class="title">Area sq.ft</span>
          <span class="info">{{building.area}}</span>
        </div>
        <div v-if="building.all_in_price" class="col-6 pl-0"><span class="title">All in Price</span>
          <span class="info"> {{building.all_in_price}}</span></div>
        </div>
        <a v-if="building.image" :href="building.image" data-fancybox="">
          <b-img  :src="building.image" fluid alt="Fluid image"></b-img>
        </a>
    </div>
</div>
</template>

<script>
export default {
  props: {
    building: {
      type: Object,
      required: true,
    },
    booked_plots: {
      type: Array,
      required: true
    },
    blocked_plots: {
      type: Array,
      required: true
    },
  },
 computed: {
    booked_flat_details() {
      return this.booked_plots.length > 0 ? this.booked_plots.map(b => b.key).includes(this.building.key) : ''
    },
    blocked_flat_details() {
      return this.blocked_plots.length > 0 ? this.blocked_plots.map(b => b.key).includes(this.building.key) : ''
    },
 }
}
</script>

<style>

</style>