import moment from 'moment'

export const projectName= "KOKAN SRUSHTI"
export const serverErrorMessage= "Something Went Wrong!"

export const custTime= "9 AM"

export const selectableTimes= ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"]

export const scheduleConfUrl = 'public_api/leads'
export const bookingUrl = 'public/bookings/new'

export const paymentPlans = ['CLP', 'EMI Scheme', 'EMI+CLP', 'Subvention 1:99', 'Subvention 10:90']

export function confCallParams(callParams){
  let toSend = {}
  toSend['name'] = callParams.custName
  toSend['email'] = callParams.custEmail
  toSend['phone'] = callParams.custPhone
  toSend['date'] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null
  toSend['time'] = callParams.custTime
  toSend['project'] = projectName
  return toSend
}

export function santizeBookingParams(bParams){
  let toSend = {}
  if(bParams.leadNo){
    toSend['ln'] = bParams.leadNo
  }
  toSend['t'] = bParams.typologyName
  toSend['in'] = bParams.inventory_id
  return toSend
}

export const masterJson =
{
  "Buildings" : [
    {
      "key": "686218e2-c235-49fa-b252-df63a54f2c6d",
      "value": "Plot 1",
      "polygon": "506.1,455.4 620.3,455.4 620.3,567.4 553.1,567.4",
      "area": "4263",
      "basic_rate": "₹ 27,70,950.00",
      "development_charge": "₹ 4,15,643.00",
      "agreement_value": "₹ 31,86,593.00",
      "gst": "₹ 74,816.00",
      "stamp_duty": "₹ 1,91,196.00",
      "registration": "₹ 31,866.00",
      "all_in_price": "₹ 34,84,471.00"
     },
     {
      "key": "b6072943-0a03-4eb8-bfca-148aa0ec9947",
      "value": "Plot 2",
      "polygon": "620.3,567.4 691.5,567.4 691.5,459.4 620.3,455.4",
      "area": "3186",
      "basic_rate": "₹ 20,70,900.00",
      "development_charge": "₹ 3,10,635.00",
      "agreement_value": "₹ 23,81,535.00",
      "gst": "₹ 55,914.00",
      "stamp_duty": "₹ 1,42,892.00",
      "registration": "₹ 23,815.00",
      "all_in_price": "₹ 26,04,156.00"
     },
     {
      "key": "b7a81618-9067-4145-b595-aa70e20eb053",
      "value": "Plot 3",
      "polygon": "432.8,307.7 523.2,309.5 523.2,410.9 480,410.9 424,307.7",
      "area": "3025",
      "basic_rate": "₹ 17,39,375.00",
      "development_charge": "₹ 2,60,906.00",
      "agreement_value": "₹ 20,00,281.00",
      "gst": "₹ 46,963.00",
      "stamp_duty": "₹ 1,20,017.00",
      "registration": "₹ 20,003.00",
      "all_in_price": "₹ 21,87,264.00"
     },
     {
      "key": "641f465f-5aef-49b0-b2ee-c3aeb0512a61",
      "value": "Plot 4",
      "area": "2390",
      "polygon": "376.3,208.2 457.6,208.2 457.6,308.2 424,307.7 ",
      "basic_rate": "₹ 13,74,250.00",
      "development_charge": "₹ 2,06,138.00",
      "agreement_value": "₹ 15,80,388.00",
      "gst": "₹ 37,105.00",
      "stamp_duty": "₹ 94,823.00",
      "registration": "₹ 15,804.00",
      "all_in_price": "₹ 17,28,120.00"
     },
     {
      "key": "69f12d97-a473-43d8-8cb0-0e17adbb0e53",
      "value": "Plot 5",
      "area": "3111",
      "polygon": "527.5,410.9 527.5,308.2 600.3,309.5 600.3,410.9 ",
      "basic_rate": "₹ 17,88,825.00",
      "development_charge": "₹ 2,68,324.00",
      "agreement_value": "₹ 20,57,149.00",
      "gst": "₹ 48,298.00",
      "stamp_duty": "₹ 1,23,429.00",
      "registration": "₹ 20,571.00",
      "all_in_price": "₹ 22,49,447.00"
     },
     {
      "key": "e35133cd-9d31-4cee-b564-2210316f9a08",
      "value": "Plot 6",
      "area": "2799",
      "polygon": "523.2,208.2 523.2,309.5 457.6,308.2 457.6,208.2",
      "basic_rate": "₹ 16,09,425.00",
      "development_charge": "₹ 2,41,414.00",
      "agreement_value": "₹ 18,50,839.00",
      "gst": "₹ 43,455.00",
      "stamp_duty": "₹ 1,11,050.00",
      "registration": "₹ 18,508.00",
      "all_in_price": "₹ 20,23,852.00"
     },
     {
      "key": "0ce8e242-bff0-4e5e-a827-e299452fb33b",
      "value": "Plot 7",
      "area": "3111",
      "polygon": "595.2,209.3 595.2,308.2 523.2,307.7 523.2,208.2",
      "basic_rate": "₹ 17,88,825.00",
      "development_charge": "₹ 2,68,324.00",
      "agreement_value": "₹ 20,57,149.00",
      "gst": "₹ 48,298.00",
      "stamp_duty": "₹ 1,23,429.00",
      "registration": "₹ 20,571.00",
      "all_in_price": "₹ 22,49,447.00"
     },
     {
      "key": "58b7366d-d4ca-49f8-b046-e6850599b570",
      "value": "Plot 8",
      "area": "3111",
      "polygon": "671.9,308.2 671.9,412.2 600.3,410.9 600.3,309.5 ",
      "basic_rate": "₹ 17,88,825.00",
      "development_charge": "₹ 2,68,324.00",
      "agreement_value": "₹ 20,57,149.00",
      "gst": "₹ 48,298.00",
      "stamp_duty": "₹ 1,23,429.00",
      "registration": "₹ 20,571.00",
      "all_in_price": "₹ 22,49,447.00"
     },
     {
      "key": "5d3923d2-69ff-4a2a-8203-713d0f9eb2ac",
      "value": "Plot 9",
      "area": "3111",
      "polygon": "669.3,208.2 669.3,308.2 595.2,308.2 595.2,209.3 ",
      "basic_rate": "₹ 17,88,825.00",
      "development_charge": "₹ 2,68,324.00",
      "agreement_value": "₹ 20,57,149.00",
      "gst": "₹ 48,298.00",
      "stamp_duty": "₹ 1,23,429.00",
      "registration": "₹ 20,571.00",
      "all_in_price": "₹ 22,49,447.00"
     },
     {
      "key": "99f3a2c8-fc5a-41aa-959c-26feafbe10ca",
      "value": "Plot 10",
      "area": "3111",
      "basic_rate": "₹ 20,22,150.00",
      "polygon": "669.3,309.5 740.8,309.5 742.9,410.9 669.3,410.9",
      "development_charge": "₹ 3,03,323.00",
      "agreement_value": "₹ 23,25,473.00",
      "gst": "₹ 54,598.00",
      "stamp_duty": "₹ 1,39,528.00",
      "registration": "₹ 23,255.00",
      "all_in_price": "₹ 25,42,854.00"
     },
     {
      "key": "b8741a1c-ea3b-4dfe-b2be-8d18a2f4203d",
      "value": "Plot 11",
      "area": "3100",
      "polygon": "742.9,208.2 742.9,307.7 669.3,308.2 669.3,208.2 ",
      "basic_rate": "₹ 20,15,000.00",
      "development_charge": "₹ 3,02,250.00",
      "agreement_value": "₹ 23,17,250.00",
      "gst": "₹ 54,405.00",
      "stamp_duty": "₹ 1,39,035.00",
      "registration": "₹ 23,173.00",
      "all_in_price": "₹ 25,33,863.00"
     },
     {
      "key": "66b51589-7318-4236-9164-6095aa62f859",
      "value": "Plot 12",
      "area": "3897",
      "polygon": "274.1,57.8 395.2,57.8 395.2,162.3 344.5,165.5 315.7,133.5 ",
      "basic_rate": "₹ 22,40,775.00",
      "development_charge": "₹ 3,36,116.00",
      "agreement_value": "₹ 25,76,891.00",
      "gst": "₹ 60,501.00",
      "stamp_duty": "₹ 1,54,613.00",
      "registration": "₹ 25,769.00",
      "all_in_price": "₹ 28,17,774.00"
     },
     {
      "key": "a673b7bc-6751-4ab2-af25-721c15cea900",
      "value": "Plot 13",
      "area": "2648",
      "polygon": "454.9,59.1 454.9,165.5 395.2,162.3 395.2,57.8 ",
      "basic_rate": "₹ 15,22,600.00",
      "development_charge": "₹ 2,28,390.00",
      "agreement_value": "₹ 17,50,990.00",
      "gst": "₹ 41,110.00",
      "stamp_duty": "₹ 1,05,059.00",
      "registration": "₹ 17,510.00",
      "all_in_price": "₹ 19,14,669.00"
     },
     {
      "key": "588febd6-795f-41d1-bb63-bdac21741281",
      "value": "Plot 14",
      "area": "3294",
      "polygon": "530.7,60.5 530.7,165.5 454.9,162.3 454.9,59.1 ",
      "basic_rate": "₹ 18,94,050.00",
      "development_charge": "₹ 2,84,108.00",
      "agreement_value": "₹ 21,78,158.00",
      "gst": "₹ 51,139.00",
      "stamp_duty": "₹ 1,30,689.00",
      "registration": "₹ 21,782.00",
      "all_in_price": "₹ 23,81,768.00"
     },
     {
      "key": "e35547ce-6d6f-471c-bca7-ef8c55f49d8b",
      "value": "Plot 15",
      "area": "3283",
      "polygon": "606.4,59.1 606.4,165.5 530.7,165.5 530.7,60.5 ",
      "basic_rate": "₹ 18,87,725.00",
      "development_charge": "₹ 2,83,159.00",
      "agreement_value": "₹ 21,70,884.00",
      "gst": "₹ 50,969.00",
      "stamp_duty": "₹ 1,30,253.00",
      "registration": "₹ 21,709.00",
      "all_in_price": "₹ 23,73,815.00"
     },
     {
      "key": "3dddbc62-98ef-4fc2-9f05-af35ffd2604a",
      "value": "Plot 16",
      "area": "3272",
      "polygon": "682.1,61.5 682.1,163.4 606.4,165.5 606.4,59.1  ",
      "basic_rate": "₹ 18,81,400.00",
      "development_charge": "₹ 2,82,210.00",
      "agreement_value": "₹ 21,63,610.00",
      "gst": "₹ 50,798.00",
      "stamp_duty": "₹ 1,29,817.00",
      "registration": "₹ 21,636.00",
      "all_in_price": "₹ 23,65,861.00"
     },
     {
      "key": "77403f43-c561-4a1a-b56c-db84164cd9ce",
      "value": "Plot 17",
      "area": "3261",
      "polygon": "757.3,63.1 757.3,163.4 682.1,163.4 682.1,61.5 ",
      "basic_rate": "₹ 21,19,650.00",
      "development_charge": "₹ 3,17,948.00",
      "agreement_value": "₹ 24,37,598.00",
      "gst": "₹ 57,231.00",
      "stamp_duty": "₹ 1,46,256.00",
      "registration": "₹ 24,376.00",
      "all_in_price": "₹ 26,65,461.00"
     },
     {
      "key": "97c31bd5-01fc-43ad-8d21-2c5b09524e87",
      "value": "Plot 18",
      "area": "3348",
      "polygon": "841.6,61.5 835.7,143.7 829.9,155.9 819.7,163.4 757.3,163.4 757.3,63.1 ",
      "basic_rate": "₹ 19,25,100.00",
      "development_charge": "₹ 2,88,765.00",
      "agreement_value": "₹ 22,13,865.00",
      "gst": "₹ 51,978.00",
      "stamp_duty": "₹ 1,32,832.00",
      "registration": "₹ 22,139.00",
      "all_in_price": "₹ 24,20,814.00"
     },
     {
      "key": "5b2ee504-c4ee-4603-b20f-18bbd2218e3b",
      "value": "Plot 19",
      "area": "2626",
      "polygon": "862.1,635.7 865.4,575 973.6,586.1 961.3,642.3 ",
      "basic_rate": "₹ 17,06,900.00",
      "development_charge": "₹ 2,56,035.00",
      "agreement_value": "₹ 19,62,935.00",
      "gst": "₹ 46,086.00",
      "stamp_duty": "₹ 1,17,776.00",
      "registration": "₹ 19,629.00",
      "all_in_price": "₹ 21,46,426.00"
     },
     {
      "key": "2271066b-f2fe-4961-a5dc-235b6ca3c5ea",
      "value": "Plot 20",
      "area": "3358",
      "polygon": "861.1,574.6 942.7,581.9 939.3,470.4 888.3,470.4 874.7,475.4 868.3,491.4 ",
      "basic_rate": "₹ 21,82,700.00",
      "development_charge": "₹ 3,27,405.00",
      "agreement_value": "₹ 25,10,105.00",
      "gst": "₹ 58,933.00",
      "stamp_duty": "₹ 1,50,606.00",
      "registration": "₹ 25,101.00",
      "all_in_price": "₹ 27,44,745.00"
     },
     {
      "key": "4673cc26-9755-4ebf-9349-16fb06672a08",
      "value": "Plot 21",
      "area": "3531",
      "polygon": "939.3,470.4 1009.9,467.4 1017.6,588.9 944.4,584.3 ",
      "basic_rate": "₹ 20,30,325.00",
      "development_charge": "₹ 3,04,549.00",
      "agreement_value": "₹ 23,34,874.00",
      "gst": "₹ 54,819.00",
      "stamp_duty": "₹ 1,40,092.00",
      "registration": "₹ 23,349.00",
      "all_in_price": "₹ 25,53,134.00"
     },
     {
      "key": "3cc9b626-d5ac-4d90-886e-9e4ba187a6fd",
      "value": "Plot 22",
      "area": "3401",
      "basic_rate": "₹ 19,55,575.00",
      "polygon": "1034.7,585 1076.3,567.4 1086.7,567.4 1082.7,464.2 1009.9,467.4 1015.7,585 ",
      "development_charge": "₹ 2,93,336.00",
      "agreement_value": "₹ 22,48,911.00",
      "gst": "₹ 52,800.00",
      "stamp_duty": "₹ 1,34,935.00",
      "registration": "₹ 22,489.00",
      "all_in_price": "₹ 24,59,135.00"
     },
     {
      "key": "12a2cde9-eca0-4175-8aa3-8b0590f8d9a5",
      "value": "Plot 23",
      "polygon": "1086.7,567.4 1160,564.2 1155.5,464.2 1082.7,464.2 ",
      "area": "3089",
      "basic_rate": "₹ 17,76,175.00",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "f10a6039-05ce-40b9-8952-a10a7a7ecbd7",
      "value": "Plot 24",
      "area": "3089",
      "polygon": "1155.5,464.2 1160,561 1230.9,561 1227.5,462.6 ",
      "basic_rate": "₹ 17,76,175.00",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "8448ddeb-41da-4eda-b4de-c5fd62ffb899",
      "value": "Plot 25",
      "area": "3089",
      "basic_rate": "₹ 17,76,175.00",
      "polygon": "1227.5,462.6 1230.9,561 1304,561 1300.8,460.2  ",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "856de8d0-acf9-4a9c-a3cb-79d8ec094cfd",
      "value": "Plot 26",
      "area": "3089",
      "polygon": "1304,561 1300.8,460.2 1374.9,457.8 1377.6,557",
      "basic_rate": "₹ 17,76,175.00",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "b006a633-2523-43ea-bba0-b60f2f8eedc9",
      "value": "Plot 27",
      "area": "3089",
      "polygon": "1374.9,457.8 1377.6,557 1450.7,557 1446.4,454.6",
      "basic_rate": "₹ 17,76,175.00",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "281a5de8-f649-4de9-be24-cf06d8c11dd5",
      "value": "Plot 28",
      "area": "3089",
      "polygon": "1446.4,454.6 1450.7,557 1521.9,553 1515.7,453 ",
      "basic_rate": "₹ 17,76,175.00",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "ec5d015d-7bfa-4b3d-9ada-386a4f6686a7",
      "value": "Plot 29",
      "area": "3089",
      "polygon": "1521.9,553 1515.7,453 1589.9,449.8 1595.5,553 ",
      "basic_rate": "₹ 17,76,175.00",
      "development_charge": "₹ 2,66,426.00",
      "agreement_value": "₹ 20,42,601.00",
      "gst": "₹ 47,957.00",
      "stamp_duty": "₹ 1,22,556.00",
      "registration": "₹ 20,426.00",
      "all_in_price": "₹ 22,33,540.00"
     },
     {
      "key": "ec654ddf-48e5-4693-8ea5-65b0478c9bd5",
      "value": "Plot 30",
      "area": "3014",
      "basic_rate": "₹ 17,33,050.00",
      "polygon": "1589.9,449.8 1693.9,449.8 1631.5,553 1595.5,553 ",
      "development_charge": "₹ 2,59,958.00",
      "agreement_value": "₹ 19,93,008.00",
      "gst": "₹ 46,792.00",
      "stamp_duty": "₹ 1,19,580.00",
      "registration": "₹ 19,930.00",
      "all_in_price": "₹ 21,79,310.00"
     },
     {
      "key": "e923e4d8-2db7-467c-8c74-b524d8afacc3",
      "value": "Plot 31",
      "area": "2680",
      "polygon": "947.2,333.5 950.4,424.2 896,427.4 880.5,419.4 874.7,403.4 880.5,333.5 ",
      "basic_rate": "₹ 17,42,000.00",
      "development_charge": "₹ 2,61,300.00",
      "agreement_value": "₹ 20,03,300.00",
      "gst": "₹ 47,034.00",
      "stamp_duty": "₹ 1,20,198.00",
      "registration": "₹ 20,033.00",
      "all_in_price": "₹ 21,90,565.00"
     },
     {
      "key": "09525f70-e736-49d8-952e-93691c8ffc78",
      "value": "Plot 32",
      "area": "3444",
      "polygon": "973.3,241.3 977.1,330.3 880.5,333.5 886.4,262.6 891.2,253 904.5,244.5 ",
      "basic_rate": "₹ 22,38,600.00",
      "development_charge": "₹ 3,35,790.00",
      "agreement_value": "₹ 25,74,390.00",
      "gst": "₹ 60,442.00",
      "stamp_duty": "₹ 1,54,463.00",
      "registration": "₹ 25,744.00",
      "all_in_price": "₹ 28,15,039.00"
     },
     {
      "key": "04a71624-1f06-48bb-a0ae-9814e1b1504c",
      "value": "Plot 33",
      "area": "2713",
      "polygon": "1018.1,329.5 1021.9,422.6 950.4,424.2 947.2,333.5 ",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "b7622994-f7d0-4b05-9cf8-1b90a0c2d89b",
      "value": "Plot 34",
      "area": "3100",
      "polygon": "1055.5,241.3 1058.1,328.7 977.1,330.3 973.3,241.3 1055.5,238.6",
      "basic_rate": "₹ 17,82,500.00",
      "development_charge": "₹ 2,67,375.00",
      "agreement_value": "₹ 20,49,875.00",
      "gst": "₹ 48,128.00",
      "stamp_duty": "₹ 1,22,993.00",
      "registration": "₹ 20,499.00",
      "all_in_price": "₹ 22,41,495.00"
     },
     {
      "key": "0aba808d-3ee2-483d-ab77-fb5230f3ca74",
      "value": "Plot 35",
      "area": "2713",
      "polygon": "1089.6,328.7 1092.8,419.9 1021.9,422.6 1018.1,329.5 ",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "e0ed0eae-6663-47c9-aaa6-2ce9fa79f209",
      "value": "Plot 36",
      "area": "2713",
      "polygon": "1160,326.6 1162.7,417.8 1092.8,419.9 1089.6,328.7",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "1e70d0e8-9b57-48b8-bb3f-c11732e2f92f",
      "value": "Plot 37",
      "area": "3100",
      "polygon": "1136,237.8 1139.2,326.6 1058.1,328.7 1055.5,238.6",
      "basic_rate": "₹ 17,82,500.00",
      "development_charge": "₹ 2,67,375.00",
      "agreement_value": "₹ 20,49,875.00",
      "gst": "₹ 48,128.00",
      "stamp_duty": "₹ 1,22,993.00",
      "registration": "₹ 20,499.00",
      "all_in_price": "₹ 22,41,495.00"
     },
     {
      "key": "566031f6-6c34-4714-8723-b1956506a558",
      "value": "Plot 38",
      "area": "2713",
      "polygon": "1230.9,324.5 1233.6,416.2 1162.7,417.8 1160,326.6",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "286c04c5-30a1-4f23-9556-4663be6a3c8a",
      "value": "Plot 39",
      "area": "3100",
      "polygon": "1216,234.3 1220.3,324.5 1139.2,326.6 1136,237.8",
      "basic_rate": "₹ 17,82,500.00",
      "development_charge": "₹ 2,67,375.00",
      "agreement_value": "₹ 20,49,875.00",
      "gst": "₹ 48,128.00",
      "stamp_duty": "₹ 1,22,993.00",
      "registration": "₹ 20,499.00",
      "all_in_price": "₹ 22,41,495.00"
     },
     {
      "key": "7f7473c8-1007-4c01-8151-6db25d7355c9",
      "value": "Plot 40",
      "area": "2713",
      "polygon": "1304,414.6 1300.8,322.9 1230.9,324.5 1233.6,416.2",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "23090cc2-fdc8-42a2-b159-ba1185754cbf",
      "value": "Plot 41",
      "area": "3100",
      "polygon": "1297.1,232.2 1300.8,322.9 1220.3,324.5 1216,234.3 ",
      "basic_rate": "₹ 17,82,500.00",
      "development_charge": "₹ 2,67,375.00",
      "agreement_value": "₹ 20,49,875.00",
      "gst": "₹ 48,128.00",
      "stamp_duty": "₹ 1,22,993.00",
      "registration": "₹ 20,499.00",
      "all_in_price": "₹ 22,41,495.00"
     },
     {
      "key": "2145fd3d-5e09-4df9-9bc4-9ed48e3d0f26",
      "value": "Plot 42",
      "area": "2713",
      "polygon": "1374.9,412.5 1371.2,320.1 1300.8,322.9 1304,414.6",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "527e86ae-c620-444a-b3d5-c873f520fb01",
      "value": "Plot 43",
      "area": "3100",
      "polygon": "1377.6,229 1381.9,319.7 1300.8,322.9 1297.1,232.2 ",
      "basic_rate": "₹ 17,82,500.00",
      "development_charge": "₹ 2,67,375.00",
      "agreement_value": "₹ 20,49,875.00",
      "gst": "₹ 48,128.00",
      "stamp_duty": "₹ 1,22,993.00",
      "registration": "₹ 20,499.00",
      "all_in_price": "₹ 22,41,495.00"
     },
     {
      "key": "7228d9cf-4db5-4832-aaa3-485ec77b90bc",
      "value": "Plot 44",
      "area": "2713",
      "polygon": "1446.4,410.9 1442.7,319.7 1371.2,320.1 1374.9,412.5 ",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "b6472765-beca-4f44-8cdd-faf98a90d771",
      "value": "Plot 45",
      "area": "2713",
      "polygon": "1515.7,408.7 1513,315.2 1440.6,317.7 1446.4,410.9 ",
      "basic_rate": "₹ 15,59,975.00",
      "development_charge": "₹ 2,33,996.00",
      "agreement_value": "₹ 17,93,971.00",
      "gst": "₹ 42,119.00",
      "stamp_duty": "₹ 1,07,638.00",
      "registration": "₹ 17,940.00",
      "all_in_price": "₹ 19,61,668.00"
     },
     {
      "key": "def74270-16b7-4c95-9987-2bfbf9f9c538",
      "value": "Plot 46",
      "area": "3961",
      "polygon": "1426.7,229 1537.6,314.3 1381.9,319.7 1377.6,229",
      "basic_rate": "₹ 22,77,575.00",
      "development_charge": "₹ 3,41,636.00",
      "agreement_value": "₹ 26,19,211.00",
      "gst": "₹ 61,494.00",
      "stamp_duty": "₹ 1,57,153.00",
      "registration": "₹ 26,192.00",
      "all_in_price": "₹ 28,64,050.00"
     },
     {
      "key": "ee171f63-aaa9-4ad4-8d4e-260d19436a20",
      "value": "Plot 47",
      "area": "3111",
      "polygon": "1653.3,404.5 1537.6,314.3 1513,315.2 1515.7,408.7",
      "basic_rate": "₹ 17,88,825.00",
      "development_charge": "₹ 2,68,324.00",
      "agreement_value": "₹ 20,57,149.00",
      "gst": "₹ 48,298.00",
      "stamp_duty": "₹ 1,23,429.00",
      "registration": "₹ 20,571.00",
      "all_in_price": "₹ 22,49,447.00"
     },
     {
      "key": "92a23206-730a-4a39-8d3c-7d644c042387",
      "value": "Plot 48",
      "area": "3143",
      "basic_rate": "₹ 18,07,225.00",
      "polygon": "1012.3,195.9 1008.5,129.8 896,133 896,179.4 900.8,192.2 911.5,195.9",
      "development_charge": "₹ 2,71,084.00",
      "agreement_value": "₹ 20,78,309.00",
      "gst": "₹ 48,795.00",
      "stamp_duty": "₹ 1,24,699.00",
      "registration": "₹ 20,783.00",
      "all_in_price": "₹ 22,72,586.00"
     },
     {
      "key": "86d4a479-0c1a-41ed-8098-ade3622f1fac",
      "value": "Plot 49",
      "area": "2551",
      "polygon": "901.9,61.5 1008.5,86.6 1008.5,129.8 896,133",
      "basic_rate": "₹ 14,66,825.00",
      "development_charge": "₹ 2,20,024.00",
      "agreement_value": "₹ 16,86,849.00",
      "gst": "₹ 39,604.00",
      "stamp_duty": "₹ 1,01,211.00",
      "registration": "₹ 16,868.00",
      "all_in_price": "₹ 18,44,532.00"
     },
     {
      "key": "75133feb-cac5-49db-831c-04e18edcf6d5",
      "value": "Plot 50",
      "area": "2949",
      "polygon": "1080,104.2 1082.7,191.7 1012.3,195.9 1008.5,86.6 ",
      "basic_rate": "₹ 16,95,675.00",
      "development_charge": "₹ 2,54,351.00",
      "agreement_value": "₹ 19,50,026.00",
      "gst": "₹ 45,783.00",
      "stamp_duty": "₹ 1,17,002.00",
      "registration": "₹ 19,500.00",
      "all_in_price": "₹ 21,32,311.00"
     },
    {
      "key": "Park",
      "value": "Park",
      "polygon": "742.9,208.2 830.7,211.8 819.5,394.2 808.7,405.4 782.7,410.9 768.7,412.2 754.3,412.2 742.9,410.9 ",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image6.jpg"
    },
    {
      "key": "ClubHouse",
      "value": "ClubHouse",
      "polygon": "691.5,567.4 801.5,570.2 806.4,475.4 798.4,467.4 786.8,458.3 753.6,455.4 721.6,458.3 698.1,457.8 693.9,458.9",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image2.jpg"
    },
    {
      "key": "Kids Play Area",
      "value": "Kids Play Area",
      "polygon": "558.3,567.4 799.5,570.2 791.9,742.6 558.3,570.2",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/image8.jpg"
    },


  ],
  "Floors" : [
    {
      "key": "floorseta",
      "value": "0 - 3rd Floor"
    },
    {
      "key": "floorsetb",
      "value": "4th - 6th Floor"
    },
    {
      "key": "floorsetc",
      "value": "7th - 9th Floor"
    },
    {
      "key": "floorsetd",
      "value": "10th - 12th Floor"
    },
    {
      "key": "floorsete",
      "value": "13th - 15th Floor"
    },
    {
      "key": "floorsetf",
      "value": "16th - 18th Floor"
    }
  ],
  "Wings" : [
    {
      "name" : "Wing 1",
      "flats" : [
        {
          "name" : "001",
          "floor" : "Floor 0",
          "status" : "Available"
        },
        {
          "name" : "002",
          "floor" : "Floor 0",
          "status" : "Booked"
        },
        {
          "name" : "003",
          "floor" : "Floor 0",
          "status" : "Available"
        },
        {
          "name" : "101",
          "floor" : "Floor 1",
          "status" : "Blocked"
        },
        {
          "name" : "102",
          "floor" : "Floor 1",
          "status" : "Available"
        },
        {
          "name" : "103",
          "floor" : "Floor 1",
          "status" : "Booked"
        },
        {
          "name" : "201",
          "floor" : "Floor 2",
          "status" : "Booked"
        },
        {
          "name" : "202",
          "floor" : "Floor 2",
          "status" : "Booked"
        },
        {
          "name" : "203",
          "floor" : "Floor 2",
          "status" : "Booked"
        },
        {
          "name" : "301",
          "floor" : "Floor 3",
          "status" : "Available"
        },
        {
          "name" : "302",
          "floor" : "Floor 3",
          "status" : "Blocked"
        },
        {
          "name" : "303",
          "floor" : "Floor 3",
          "status" : "Booked"
        },
        {
          "name" : "401",
          "floor" : "Floor 4",
          "status" : "Available"
        },
        {
          "name" : "402",
          "floor" : "Floor 4",
          "status" : "Blocked"
        },
        {
          "name" : "403",
          "floor" : "Floor 4",
          "status" : "Booked"
        }
      ]
    },
    {
      "name" : "Wing 2",
      "flats" : [
        {
          "name" : "001",
          "floor" : "Floor 0",
          "status" : "Booked"
        },
        {
          "name" : "002",
          "floor" : "Floor 0",
          "status" : "Available"
        },
        {
          "name" : "003",
          "floor" : "Floor 0",
          "status" : "Blocked"
        },
        {
          "name" : "101",
          "floor" : "Floor 1",
          "status" : "Blocked"
        },
        {
          "name" : "102",
          "floor" : "Floor 1",
          "status" : "Available"
        },
        {
          "name" : "103",
          "floor" : "Floor 1",
          "status" : "Booked"
        }
      ]
    },
    {
      "name" : "Wing 3",
      "flats" : [
        {
          "name" : "101",
          "floor" : "Floor 1",
          "status" : "Blocked"
        },
        {
          "name" : "102",
          "floor" : "Floor 1",
          "status" : "Available"
        },
        {
          "name" : "103",
          "floor" : "Floor 1",
          "status" : "Booked"
        },
        {
          "name" : "201",
          "floor" : "Floor 2",
          "status" : "Booked"
        },
        {
          "name" : "202",
          "floor" : "Floor 2",
          "status" : "Booked"
        },
        {
          "name" : "203",
          "floor" : "Floor 2",
          "status" : "Available"
        }
      ]
    }
  ]
}