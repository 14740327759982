<template>
  <div id="app">
    <div id="loading-wrapper" v-if="isLoading">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  mounted(){
    this.$store.dispatch('setLoading', false);
    if(this.$route.query.ENAUCP_ID){
      localStorage.setItem('enrichr-augment-p1-cp-id', this.$route.query.ENAUCP_ID)
    }
  }
}
</script>
