<template>
  <div class="outer-wrapper height-s-fix">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="brand-logo-mob text-center">
            <img src="@/assets/images/defaultlogo.jpg" alt="" class="img-fluid">
          </div>
        </div>
        <div class="ct-col col-md-9">
          <div class="plan-holder">
            <MasterSVG :booked_plots="booked_plots" :blocked_plots="blocked_plots"/>
          </div>
          <b-button v-b-modal.modal-1  class="btn btn-about">View about Project</b-button>
          <div class="info-section ">
            <div class="info-wrapper">
              <div class="row m-0">
                <div class="col-md-5 about-section">
                  <h2>About the Project </h2>
                  <p>The project is located in Chandranagar, Dapoli - a perfect hill station in Dapoli district. Cool sea breeze during the day and low temperatures make it an ideal location for your second home. The project is centrally located between Ladghar beach and the Dapoli town centre. An Ideal location to have a perfect weekend getaway home from Mumbai and Pune. Witness the sunrise and sunset all from the comfort of your chair.</p>
                  <p> The project is developed within in an area just under 7 acres with a total of 50 NA plots. The average size of NA plots ranges from 2300 - 4200 sq ft. The price of plots starting from 18L + taxes.</p>
                </div>
                <div class="col-md-7 amenities-section">
                  <h2>Project Amenities </h2>
                  <ul class="row">
                    <li class="col-6 col-sm-6 col-md-4">Street Lighting</li>
                    <li class="col-6 col-sm-6 col-md-4">24*7 Security</li>
                    <li class="col-6 col-sm-6 col-md-4">Yoga and Meditation Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Kids Rock Climbing</li>
                    <li class="col-6 col-sm-6 col-md-4">Gazebo</li>
                    <li class="col-6 col-sm-6 col-md-4">Common Space Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Garden</li>
                    <li class="col-6 col-sm-6 col-md-4">Kids Play Area</li>
                    <li class="col-6 col-sm-6 col-md-4">Roadside Plantation</li>
                    <li class="col-6 col-sm-6 col-md-4">Kite Flying Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Green Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Walking track</li>
                    <li class="col-6 col-sm-6 col-md-4">Light Connection for each plot</li>
                    <li class="col-6 col-sm-6 col-md-4">Club House</li>
                    <li class="col-6 col-sm-6 col-md-4">Water Connection for Each Plot</li>
                    <li class="col-6 col-sm-6 col-md-4">Gymnasium</li>
                    <li class="col-6 col-sm-6 col-md-4">Internal Roads - 9m and 12m wide</li>
                    <li class="col-6 col-sm-6 col-md-4">Entrance Gate</li>
                    <li class="col-6 col-sm-6 col-md-4">Demarcated NA Plots with Individual 7/12</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ct-col col-md-3">
          <Typologies  :booked_plots="booked_plots" :blocked_plots="blocked_plots"/>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="About The Project" hide-footer>
      <div class="info-section ">
            <div class="info-wrapper">
              <div class="row m-0">
                <div class="col-md-5 about-section">
                  <h2>About the Project </h2>
                  <p>The project is located in Chandranagar, Dapoli - a perfect hill station in Dapoli district. Cool sea breeze during the day and low temperatures make it an ideal location for your second home. The project is centrally located between Ladghar beach and the Dapoli town centre. An Ideal location to have a perfect weekend getaway home from Mumbai and Pune. Witness the sunrise and sunset all from the comfort of your chair.</p>
                  <p> The project is developed within in an area just under 7 acres with a total of 50 NA plots. The average size of NA plots ranges from 2300 - 4200 sq ft. The price of plots starting from 18L + taxes.</p>
                </div>
                <div class="col-md-7 amenities-section">
                  <h2>Project Amenities </h2>
                  <ul class="row">
                    <li class="col-6 col-sm-6 col-md-4">Street Lighting</li>
                    <li class="col-6 col-sm-6 col-md-4">24*7 Security</li>
                    <li class="col-6 col-sm-6 col-md-4">Yoga and Meditation Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Kids Rock Climbing</li>
                    <li class="col-6 col-sm-6 col-md-4">Gazebo</li>
                    <li class="col-6 col-sm-6 col-md-4">Common Space Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Garden</li>
                    <li class="col-6 col-sm-6 col-md-4">Kids Play Area</li>
                    <li class="col-6 col-sm-6 col-md-4">Roadside Plantation</li>
                    <li class="col-6 col-sm-6 col-md-4">Kite Flying Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Green Zone</li>
                    <li class="col-6 col-sm-6 col-md-4">Walking track</li>
                    <li class="col-6 col-sm-6 col-md-4">Light Connection for each plot</li>
                    <li class="col-6 col-sm-6 col-md-4">Club House</li>
                    <li class="col-6 col-sm-6 col-md-4">Water Connection for Each Plot</li>
                    <li class="col-6 col-sm-6 col-md-4">Gymnasium</li>
                    <li class="col-6 col-sm-6 col-md-4">Internal Roads - 9m and 12m wide</li>
                    <li class="col-6 col-sm-6 col-md-4">Entrance Gate</li>
                    <li class="col-6 col-sm-6 col-md-4">Demarcated NA Plots with Individual 7/12</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  </b-modal>
  </div>
</template>
<script>
  import MasterSVG from '@/components/MasterSVG.vue';
  import Typologies from '@/components/Home/Typologies.vue';
  export default {
    components: {
      MasterSVG,
      Typologies
    },
    data() {
      return {
        booked_plots: [],
        blocked_plots: []
      }
    },
    created() {
      this.$axios.get(`public_api/leads/booking_status`)
      .then((res) => {
        if (res.data.booking_data) {
          this.booked_plots = res.data.booking_data
          this.blocked_plots = res.data.blocking_data
        }
      })
      .catch((err) => console.log(err));
    },
    mounted() {
      $(".side-menu.next").css({
        "display": "none",
      });
      $('.nextButton').click(function() {
        $(".side-menu.prev").css({
          "display": "none",
        });
        $(".side-menu.next").css({
          "display": "block",
        });
      });
      $('.prevButton').click(function() {
        $(".side-menu.prev").css({
          "display": "block",
        });
        $(".side-menu.next").css({
          "display": "none",
        });
      });
    }
  }
</script>
<style scoped>
  .info-section {
    background: url(../assets/images/about-bg.jpg) no-repeat center 40px;
    padding: 15px;
    border: 6px solid #fff;
    border-top: none;
    z-index: 1;
    position: relative;
    background-size: cover;
    margin-top: -40px;
  }

  .info-wrapper {
    background: #fff;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
  }

  .info-wrapper h2,
  .amenities-section h2 {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }

  .info-wrapper p {
    font-size: 11px;
    font-weight: 400;
    color: #000;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .about-section {
    background: #F5F5F5;
    border-radius: 8px;
    padding: 10px;
  }

  .amenities-section ul {
    list-style: none;
    padding: 0;
  }

  .amenities-section ul li {
    font-weight: 400;
    font-size: 11px;
  }

  .height-s-fix {
    height: 100vh;
  }

  .brand-logo-mob {
    display: none;
  }
  .btn.btn-about{
    display: none;
  }
  @media (max-width: 1240px) {}

  @media (max-width: 991px) {
    .info-wrapper {
      position: relative;
    }

    .amenities-section {
      margin-top: 20px;
    }

    .side-menu {
      margin-top: 30px;
    }
   
   
  }

  @media (max-width: 767px) {
    .brand-logo-mob {
      background: #fff;
      padding: 10px;
      display: block;
    }
    .btn.btn-about {
    position: absolute;
    bottom: 10px;
    background: #000;
    font-size: 13px;
    color: #fff;
    padding: 5px;
    left: 25px;
    display: inline-block;
   }

    .info-section {
      background: url(../assets/images/about-bg.jpg) no-repeat center;
      background-size: cover;
      margin: 0;
      display: none;
    }
    .modal  .info-section{
      display: block;
      border:0;
    }
  }
</style>